<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-outdoorsy">
      <h2 class="onboarding-content-title">Outdoorsy or in?</h2>
      <p>Pick one.</p>
      <div class="onboarding-outdoorsy-list">
        <template v-for="(option, index) in activeItem.options">
          <button
            :key="option.id"
            class="onboarding-outdoorsy-list-item"
            :data-active="activeOption === option.id"
            @click="selectOption(option.id)"
          >
            <img :src="option.src" width="300" height="180" />
          </button>
          <p
            v-if="index !== activeItem.options.length - 1"
            style="font-family: DDin; font-size: 14px"
          >
            OR
          </p>
        </template>
      </div>
    </div>
    <XButton
      type="large"
      style="margin-top: auto"
      :disabled="activeOption === undefined"
      @click="next"
    >
      Next
    </XButton>
  </div>
</template>

<script>
import XButton from '../../components/common/global/XButton.vue'

const list = [
  {
    id: 1,
    options: [
      { id: 1, src: require('@/assets/onboarding/outdoorsy/sandy-beach.png') },
      { id: 2, src: require('@/assets/onboarding/outdoorsy/downtown.png') },
    ],
  },
  {
    id: 2,
    options: [
      { id: 1, src: require('@/assets/onboarding/outdoorsy/mountains.png') },
      { id: 2, src: require('@/assets/onboarding/outdoorsy/books.png') },
    ],
  },
  {
    id: 3,
    options: [
      { id: 1, src: require('@/assets/onboarding/outdoorsy/woods.png') },
      { id: 2, src: require('@/assets/onboarding/outdoorsy/city-center.png') },
    ],
  },
]

export default {
  name: 'OnboardingOutdoorsy',
  components: { XButton },
  data() {
    return {
      activeIndex: 0,
      activeOption: undefined,
      list,
    }
  },
  computed: {
    activeItem() {
      return this.list[this.activeIndex]
    },
  },
  methods: {
    selectOption(id) {
      this.activeOption = id
    },
    next() {
      if (this.activeIndex === this.list.length - 1) {
        this.$router.push('/onboarding/mingle')
        return
      }
      this.activeIndex += 1
      this.activeOption = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-outdoorsy {
  display: grid;
  gap: 1rem;
}
.onboarding-outdoorsy-list {
  justify-items: center;
  display: grid;
  gap: 0.5rem;
}

.onboarding-outdoorsy-list-item {
  border-width: 1.5px;
  border-style: dashed;
  border-color: #d1d1d1;
  padding: 0.5rem;
  border-radius: 8px;
  & > img {
    border-radius: inherit;
  }
  &[data-active='true'] {
    border-color: var(--color-primary);
  }
}
</style>
